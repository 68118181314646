import { Box, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import SellBikeImage from '../../assets/sell-bike-ad.png';
import { UniversalButton } from '../../components';
import routeConfiguration from '../../routing/routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import Checkmark from './checkmark.svg';
import GuyPhotographingBike from './images/guy_photographing_bike.png';

import css from './SectionWhyGearro.module.css';

export const SectionWhyGearro = () => {
  const history = useHistory();
  const intl = useIntl();

  const renderWhyGearroItem = (title, description) => (
    <Stack direction="row" gap={2} alignItems="start">
      <img alt="checkmark" src={Checkmark} width={18} height={13} style={{ marginTop: 8 }} />
      <Stack direction="column" gap={1}>
        <Typography color="#07020D" lineHeight={1} className={css.why__itemTitle}>
          {title}
        </Typography>
        <Typography color="#495057" fontSize={16} fontWeight={400} mt={1}>
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      maxWidth={1320}
      mx="auto"
      overflow="hidden"
      sx={{
        maxWidth: 1320,
        borderRadius: 4,
        '@media (max-width: 1439px)': { maxWidth: '100vw', borderRadius: 0 },
      }}
    >
      <Box component="img" src={GuyPhotographingBike} alt="Sell your bike" width={{ xs: '100%', md: '50%' }} height={{ xs: 'auto', md: '100%' }} />
      <Stack
        width={{ xs: '100%', md: '50%' }}
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        bgcolor="#F3F3F3"
        px={{ xs: 2, md: 6 }}
        py={6}
        gap={2}
        sx={{
          width: '100%',
          '@media (max-width: 1439px)': { maxWidth: '100vw', borderRadius: 0 },
          '& button': {
            width: { xs: '100%', lg: 280 },
          },
        }}
      >
        <Typography color="#07020D" fontSize={42} fontWeight={500} mb={2} className={css.why__title}>
          {intl.formatMessage({ id: 'landing_page.why_gearro.why' })}
        </Typography>

        {renderWhyGearroItem(
          intl.formatMessage({ id: 'landing_page.why_gearro.title_1' }),
          intl.formatMessage({ id: 'landing_page.why_gearro.description_1' }),
        )}
        {renderWhyGearroItem(
          intl.formatMessage({ id: 'landing_page.why_gearro.title_2' }),
          intl.formatMessage({ id: 'landing_page.why_gearro.description_2' }),
        )}
        {renderWhyGearroItem(
          intl.formatMessage({ id: 'landing_page.why_gearro.title_3' }),
          intl.formatMessage({ id: 'landing_page.why_gearro.description_3' }),
        )}
      </Stack>
    </Stack>
  );
};
