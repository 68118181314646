import React from 'react';
import useIsMobileScreen from '../../../hooks/useIsMobileScreen';
import RecentItemCard from './RecentItemCard/RecentItemCard';

import css from './SectionRecentItems.module.css';

function SectionRecentItems(props) {
  const { recentListings, intl, likedListingsIds, addUserFavorite, removeUserFavorite, currentUserId } = props;

  const isMobile = useIsMobileScreen(768);

  return (
    <div className={css.root}>
      {isMobile ? (
        <>
          {recentListings.map(listing => (
            <RecentItemCard
              rootClassName={css.recentItemCardRoot}
              key={listing.id.uuid}
              listing={listing}
              intl={intl}
              likedListingsIds={likedListingsIds}
              addUserFavorite={addUserFavorite}
              removeUserFavorite={removeUserFavorite}
              currentUserId={currentUserId}
            />
          ))}
        </>
      ) : (
        <>
          {recentListings.slice(0, 4).map(listing => (
            <RecentItemCard
              rootClassName={css.recentItemCardRoot}
              key={listing.id.uuid}
              listing={listing}
              intl={intl}
              likedListingsIds={likedListingsIds}
              addUserFavorite={addUserFavorite}
              removeUserFavorite={removeUserFavorite}
              currentUserId={currentUserId}
            />
          ))}
        </>
      )}
    </div>
  );
}

export default SectionRecentItems;
