import { Box, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import SellBikeImage from '../../assets/sell-bike-ad.png';
import { UniversalButton } from '../../components';
import routeConfiguration from '../../routing/routeConfiguration';
import { pathByRouteName } from '../../util/routes';

export const SectionCreateListing = () => {
  const history = useHistory();
  const intl = useIntl();

  const handleSellClick = () => {
    const path = pathByRouteName('NewListingAddImportLink', routeConfiguration());
    history.push(path);
  };

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent="space-between"
      maxWidth={984}
      width="100%"
      mx="auto"
      p={4}
      border={1}
      borderColor="#E9ECEF"
      bgcolor="#F3F3F3"
      overflow="hidden"
      alignItems="center"
      borderRadius={4}
      mt={8}
      mb={4}
      gap={{ xs: 3, md: 0 }}
    >

      <Typography color="#000" fontSize={24} fontWeight={500} dangerouslySetInnerHTML={{
        __html:
          intl.formatMessage({ id: 'landing_page.create_listing.create_listing' },
            {
              seconds: `<span style="color: #235641; font-weight: inherit; font-size: inherit">10</span>`,
            })
      }} />

      <UniversalButton type="primary" onClick={handleSellClick} mt={2} style={{ width: 'auto', whiteSpace: 'nowrap', padding: '8px 16px' }}>
        {intl.formatMessage({ id: 'landing_page.create_listing.import_from_link' })}
      </UniversalButton>
    </Stack >
  );
};
