import { Box, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import SellBikeImage from '../../assets/sell-bike-ad.png';
import { UniversalButton, NamedLink } from '../../components';
import routeConfiguration from '../../routing/routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import { filters } from '../../config/marketplace-custom-config';
import { AnimatedListItem } from './AnimatedListItem';

import SnowboardIcon from '../../assets/icons/categories/medium/snowboard.webp';
import HelmetIcon from '../../assets/icons/categories/medium/helmet.webp';
import SnowboardTop from '../../assets/icons/categories/medium/snowboard_top.png';
import SkisIcon from '../../assets/icons/categories/medium/skis.webp';
import SkisTop from '../../assets/icons/categories/medium/skis_top.png';

import css from './SectionCategoriesGrid.module.css';
import { HorizontalList } from './HorizontalList';
import Spacer from '../../components/Spacer/Spacer';

function CategoryCard(props) {
  const { name, categoryKey, icon, history, categoryType = 'bike', isMobile = false } = props;

  return (
    <NamedLink
      name="SearchPage"
      params={{ type: categoryType, category: categoryKey }}
      state={{ prevPath: history.location.pathname }}
      style={{ display: isMobile ? 'flex' : null }}
    >
      <AnimatedListItem height="100%" width={isMobile ? '40vw' : null} >
        <Stack gap={2} p={2} alignItems="center" height="100%" >
          <Box
            component="img"
            src={icon}
            height='auto'
            width='100%'
            sx={{ objectFit: 'contain', objectPosition: 'center', aspectRatio: 1, maxHeight: 95 }}
          />
          <Typography fontSize={{ xs: 16, md: 18 }} fontWeight={400} textAlign="center" mt='auto'>
            {name}
          </Typography>
        </Stack>
      </AnimatedListItem>
    </NamedLink>
  );
}

export const SectionCategoriesGrid = () => {
  const history = useHistory();
  const intl = useIntl();
  const categories = filters.find(filter => filter.id === 'category').config.options;

  const categoriesForDisplay = ['mountain', 'gravel', 'electric'];

  const categoryCards = categoriesForDisplay
    .map(key => categories
      .find(category => category.key === key));

  const categoryCardsWithWinter = [
    {
      categoryType: 'winter',
      categoryKey: 'snowboard',
      key: 'snowboard',
      history,
      logo: SnowboardTop,
    },
    {
      categoryType: 'winter',
      categoryKey: 'skis',
      key: 'skis',
      history,
      logo: SkisTop,
    },
    {
      categoryType: 'winter',
      categoryKey: 'accessories',
      key: 'accessories',
      history,
      logo: HelmetIcon,
    },
    ...categoryCards,
  ];

  const handleShopClick = () => {
    const path = pathByRouteName('SearchPage', routeConfiguration());
    history.push(path);
  };

  return (
    <Stack
      direction={{ xs: 'column-reverse', md: 'row' }}
      maxWidth={1320}
      mx="auto"
      mb={6}
      bgcolor='#F3F3F3'
      overflow="hidden"
      py={{ xs: 2, md: 8 }}
      px={{ xs: 2, md: 4 }}
      sx={{
        maxWidth: 1320,
        borderRadius: 4,
        '@media (max-width: 1439px)': { maxWidth: '100vw', borderRadius: 0 },
      }}
    >
      {/* Dekstop */}
      <div className={`${css.grid} ${css.mobileHidden}`}>
        {categoryCardsWithWinter
          .map(category => (
            <CategoryCard
              name={intl.formatMessage({ id: `categories.${category.key}` })}
              key={category.key}
              categoryKey={category.key}
              categoryType={category.categoryType}
              icon={category.bigLogo ?? category.logo}
              history={history}
            />
          ))}
      </div>

      <HorizontalList className={`${css.desktopHidden} ${css.horizontalList}`}>
        {categoryCardsWithWinter.map((category) => (
          <CategoryCard
            name={intl.formatMessage({ id: `categories.${category.key}` })}
            key={category.key}
            categoryKey={category.key}
            categoryType={category.categoryType}
            isMobile
            icon={category.bigLogo ?? category.logo}
            history={history}
          />
        ))}
      </HorizontalList>
      <Stack
        width="50%"
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        px={{ xs: 0, md: 3 }}
        py={3}
        gap={2}
        sx={{
          width: { xs: '100%', md: '50%' },
          '& button': {
            width: { xs: '100%', md: 280 },
          },
        }}
      >
        <Typography color="#000000" className={css.grid__title}>
          {intl.formatMessage({ id: 'landing_page.grid.gear_fuels_passion' })}
          <UniversalButton onClick={handleShopClick} className={css.desktopHidden} width="auto" type="secondary" style={{
            width: 'auto',
            padding: '8px 16px',
            lineHeight: '1',
            height: 'auto',
            whiteSpace: 'nowrap',
          }}>
            {intl.formatMessage({ id: 'landing_page.grid.shop_all' })}
          </UniversalButton>
        </Typography>
        <Typography color="#495057" className={css.grid__description}>
          {intl.formatMessage({ id: 'landing_page.grid.gear_fuels_passion_description' })}
        </Typography>
        <UniversalButton type="primary" onClick={handleShopClick} mt={2} className={css.mobileHidden}>
          {intl.formatMessage({ id: 'landing_page.grid.shop_all' })}
        </UniversalButton>
      </Stack>
    </Stack>
  );
};
