import { Box, Stack } from '@mui/material';

export const CarouselNavigationBubbles = ({ count, activeIndex, onClick }) => (
  <Stack
    position="absolute"
    direction="row"
    alignItems="center"
    justifyContent="center"
    gap={1}
    bottom={theme => theme.spacing(2)}
    left="50%"
    sx={{ transform: 'translateX(-50%)' }}
  >
    {Array.from({ length: count }).map((_, index) => (
      <Box
        key={`bubble_${index}`}
        width={8}
        height={8}
        borderRadius={4}
        border="1px solid"
        borderColor="white"
        bgcolor={index === activeIndex ? 'white' : 'transparent'}
        sx={{ cursor: 'pointer' }}
        onClick={() => onClick(index)}
      />
    ))}
  </Stack>
);
