import { Box } from '@mui/material';

export const CarouselItemBackground = ({ src, alt, display }) => (
  <Box
    component="img"
    position="absolute"
    top={0}
    left={0}
    width="100%"
    height="100%"
    display={display}
    sx={{ objectFit: 'cover', objectPosition: { xs: 'top', md: 'center' } }}
    src={src}
    alt={alt}
    zIndex={-1}
  />
);
