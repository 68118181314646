import { Paper } from '@mui/material';

export const AnimatedListItem = ({ children, height = 'auto', width = null }) => (
  <Paper
    variant="outlined"
    sx={{
      flex: { xs: 'unset', md: 1 },
      borderRadius: '12px',
      transition: 'transform 0.3s ease-in-out',
      '&:hover': { transform: 'scale(0.98)' },
      height,
      width,
    }}
  >
    {children}
  </Paper>
);
