import { Box } from '@mui/material';
import ArrowLeft from '../../../../assets/icons/arrows/back.png';
import ArrowRight from '../../../../assets/icons/arrows/right.png';

export const CarouselArrowButton = ({ direction, onClick }) => (
  <Box
    position="absolute"
    top="50%"
    left={theme => (direction === 'left' ? theme.spacing(3) : 'auto')}
    right={theme => (direction === 'right' ? theme.spacing(3) : 'auto')}
    height={32}
    width={32}
    display={{ xs: 'none', sm: 'flex' }}
    alignItems="center"
    justifyContent="center"
    bgcolor="rgba(0, 0, 0, 0.5)"
    borderRadius="50%"
    sx={{ transform: 'translateY(-50%)', cursor: 'pointer', '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.8)' } }}
    onClick={onClick}
  >
    <Box
      component="img"
      src={direction === 'left' ? ArrowLeft : ArrowRight}
      alt={direction}
      width={24}
      height={24}
      mr={direction === 'left' ? '2px' : 0}
      ml={direction === 'right' ? '2px' : 0}
      sx={{ filter: 'invert(1)' }}
    />
  </Box>
);
